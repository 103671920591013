import '../../src/global.css'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-quill/dist/quill.snow.css'
import { useRoutes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import { MatxTheme } from './components'
// ALL CONTEXTS
import { AuthProvider } from './contexts/JWTAuthContext'
import SettingsProvider from './contexts/SettingsContext'
import { DataProvider } from './contexts/DataContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
// ROUTES
import routes from './routes'
// FAKE SERVER
import '../fake-db'

export default function App() {
  const content = useRoutes(routes)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <AuthProvider>
          <DataProvider>
            <MatxTheme>
              <CssBaseline />
              {content}
            </MatxTheme>
          </DataProvider>
        </AuthProvider>
      </SettingsProvider>
    </LocalizationProvider>
  )
}
