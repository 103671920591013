// import axios from "axios";
// import { toast } from "react-toastify";
// export const statusColorMap = {
//   placed: "primary",
//   packed: "secondary",
//   pickup: "default",
//   delivering: "secondary",
//   complete: "success"
// };
// function getAccessToken() {
//   return localStorage.getItem("accessToken");
// }

// export const checkLoginError = (err, routr) => {
//   if (err.response?.status === 401) {
//     toast.error("Your token has expired, Kindly login again!");
//     localStorage.clear();
//     setTimeout(() => {
//       routr ? routr.push("/session/login") : window.location.navigate("/session/login");
//     }, 500);
//     return true;
//   } else if (err.message === "Request timed out") {
//     routr.push("/500");
//   } else {
//     toast.error(
//       typeof err?.response?.data?.message === "string"
//         ? err?.response?.data?.message
//         : "Something went wrong on our side"
//     );
//   }
// };

// const headersWithToken = () => {
//   return {
//     headers: {
//       "x-lang": "en",
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${getAccessToken()}`
//     }
//   };
// };

// const headersWithFileToken = () => {
//   return {
//     headers: {
//       "x-lang": "en",
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${getAccessToken()}`
//     }
//   };
// };

// const axiosInstance = axios.create({
//   baseURL: `${process.env.REACT_APP_BASE_URL}`,
//   headers: {
//     "x-lang": "en",
//     "Content-Type": "application/json"
//   }
// });

// axiosInstance.interceptors.request.use(
//   (request) => {
//     const accessToken = sessionStorage.getItem("accessToken");
//     if (accessToken) {
//       request.headers["Authorization"] = `Bearer ${accessToken}`;
//     }
//     return request;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (response) => response, // Directly return successful responses.
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
//       try {
//         const refreshToken = localStorage.getItem("refreshToken"); // Retrieve the stored refresh token.
//         // Make a request to your auth server to refresh the token.
//         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`, {
//           refreshToken
//         });
//         const { accessToken, refreshToken: newRefreshToken } = response.data;
//         // Store the new access and refresh tokens.
//         localStorage.setItem("accessToken", accessToken);
//         localStorage.setItem("refreshToken", newRefreshToken);
//         // Update the authorization header with the new access token.
//         axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
//         return axiosInstance(originalRequest); // Retry the original request with the new access token.
//       } catch (refreshError) {
//         // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
//         console.error("Token refresh failed:", refreshError);
//         localStorage.removeItem("accessToken");
//         localStorage.removeItem("refreshToken");
//         window.location.href = "/login";
//         return Promise.reject(refreshError);
//       }
//     }
//     return Promise.reject(error); // For all other errors, return the error as is.
//   }
// );

// export const getRequest = async (endpoint, router) => {
//   try {
//     const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
//     const timeout = 10000; // 10 seconds

//     const timeoutPromise = new Promise((_, reject) => {
//       setTimeout(() => reject(new Error("Request timed out")), timeout);
//     });
//     const res = await Promise.race([axios.get(url, headersWithToken()), timeoutPromise]);
//     if (res?.data?.statusCode === 200) {
//       return res?.data;
//     }
//   } catch (error) {
//     checkLoginError(error, router);
//     console.log(error);
//   }
// };

// export const UploadFiles = async (file) => {
//   try {
//     let form = new FormData();
//     form.append("file", file);
//     let url = `${process.env.REACT_APP_BASE_URL}/upload/`;
//     const res = await axios.post(url, form, headersWithFileToken());
//     if (res?.data?.statusCode === 200) {
//       return res?.data?.data?.url;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     checkLoginError(error);
//     console.log(error);
//     return false;
//   }
// };

// export const UploadMultiple = async (files) => {
//   const uploadedFiles = [...files]; // Create a copy of the files array

//   for (let i = 0; i < uploadedFiles.length; i++) {
//     const file = uploadedFiles[i];

//     // Check if the item is an object (a file) or already a string (URL)
//     if (typeof file === "object" && file !== null) {
//       try {
//         // Upload the file and replace the object with the returned URL
//         const url = await UploadFiles(file);
//         uploadedFiles[i] = url; // Replace the object with the URL
//       } catch (error) {
//         console.error(`Failed to upload ${file.path}`, error);
//       }
//     } else {
//       console.log(`File ${file} is already uploaded. Skipping...`);
//     }
//   }

//   return uploadedFiles; // Return the updated array with URLs only
// };

// export const postRequest = async (endpoint, data, router) => {
//   try {
//     let url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
//     const res = await axios.post(url, data, headersWithToken());
//     if (res?.data?.statusCode === 200) {
//       return res?.data;
//     } else {
//       return res;
//     }
//   } catch (error) {
//     console.log(error);
//     checkLoginError(error, router);
//   }
// };

// export const putRequest = async (endpoint, data, router) => {
//   try {
//     const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
//     const res = await axios.put(url, data, headersWithToken());
//     if (res?.data?.statusCode === 200) {
//       return res?.data;
//     }
//   } catch (error) {
//     checkLoginError(error, router);
//   }
// };

// export const patchRequest = async (endpoint, data, router) => {
//   try {
//     const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
//     const res = await axios.patch(url, data, headersWithToken());
//     if (res?.data?.statusCode === 200) {
//       return res?.data;
//     }
//   } catch (error) {
//     checkLoginError(error, router);
//   }
// };

// export const deleteRequest = async (endpoint, data, router) => {
//   try {
//     const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
//     const res = await axios.delete(url, {
//       headers: {
//         "x-lang": "en",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${getAccessToken()}`
//       },
//       data: data
//     });
//     if (res?.data?.statusCode === 200) {
//       return res?.data;
//     } else {
//       // console.log(res);
//       return false;
//     }
//   } catch (error) {
//     checkLoginError(error, router);
//   }
// };

import axios from "axios";
import { toast } from "react-toastify";

export const statusColorMap = {
  placed: "primary",
  packed: "secondary",
  pickup: "default",
  delivering: "secondary",
  complete: "success"
};

function getAccessToken() {
  return localStorage.getItem("accessToken");
}

export const checkLoginError = (err, router) => {
  if (err.message === "Request timed out") {
    router?.push("/500");
  } else {
    toast.error(
      typeof err?.response?.data?.message === "string"
        ? err?.response?.data?.message
        : "Something went wrong on our side"
    );
  }
};

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`
});

axiosInstance.interceptors.request.use(
  (request) => {
    const accessToken = getAccessToken();
    request.headers["x-lang"] = "en";
    request.headers["Content-Type"] =
      request.data instanceof FormData ? "multipart/form-data" : "application/json";

    if (accessToken) {
      request.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/refresh-token`,
          {
            refreshToken
          },
          {
            headers: {
              "x-lang": "en",
              "Content-Type": "application/json"
            }
          }
        );

        const { accessToken, refreshToken: newRefreshToken } = res?.data?.data;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.clear();
        window.location.href = "/session/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const getRequest = async (endpoint, router) => {
  try {
    const timeout = 10000;
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), timeout)
    );
    const res = await Promise.race([axiosInstance.get(endpoint), timeoutPromise]);

    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
  } catch (error) {
    checkLoginError(error, router);
    console.error(error);
  }
};

export const UploadFiles = async (file) => {
  try {
    let form = new FormData();
    form.append("file", file);
    const res = await axiosInstance.post("/upload/", form);

    if (res?.data?.statusCode === 200) {
      return res?.data?.data?.url;
    }
  } catch (error) {
    checkLoginError(error);
    console.error(error);
    return false;
  }
};

export const UploadMultiple = async (files) => {
  const uploadedFiles = [...files];

  for (let i = 0; i < uploadedFiles.length; i++) {
    const file = uploadedFiles[i];
    if (typeof file === "object" && file !== null) {
      try {
        const url = await UploadFiles(file);
        uploadedFiles[i] = url;
      } catch (error) {
        console.error(`Failed to upload ${file.path}`, error);
      }
    } else {
      console.log(`File ${file} is already uploaded. Skipping...`);
    }
  }
  return uploadedFiles;
};

export const postRequest = async (endpoint, data, router) => {
  try {
    const res = await axiosInstance.post(endpoint, data);

    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
    return res;
  } catch (error) {
    checkLoginError(error, router);
    console.error(error);
  }
};

export const putRequest = async (endpoint, data, router) => {
  try {
    const res = await axiosInstance.put(endpoint, data);

    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
  } catch (error) {
    checkLoginError(error, router);
    console.error(error);
  }
};

export const patchRequest = async (endpoint, data, router) => {
  try {
    const res = await axiosInstance.patch(endpoint, data);

    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
  } catch (error) {
    checkLoginError(error, router);
    console.error(error);
  }
};

export const deleteRequest = async (endpoint, data, router) => {
  try {
    const res = await axiosInstance.delete(endpoint, { data });

    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
    return false;
  } catch (error) {
    checkLoginError(error, router);
    console.error(error);
  }
};
